import React, { useEffect, useState } from "react";
import { Table, Button, notification } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { TableWrapper } from "Styles/global";
import InfotainmentDrawer from "./InfotainmentDrawer";
import { useDispatch } from "react-redux";
import { getInfotainmentList, deleteInfotainmentBulk } from "Redux/App/Actions/Infotainment";
import DrawerComponent from "Components/Shared/Drawer";

const Infotainment = () => {
  const dispatch = useDispatch();

  const [infotainmentData, setInfotainmentData] = useState({
    data: [],
    loading: false
  });

  const [drawerOpen, setDrawerOpen] = useState({
    visible: false,
    record: null,
    isEdit: false
  });

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    loadInfotainment();
  }, []);

  const loadInfotainment = async () => {
    try {
      setInfotainmentData((prev) => ({ ...prev, loading: true }));
      const result = await dispatch(getInfotainmentList());
      setInfotainmentData({ data: result || [], loading: false });
    } catch (error) {
      setInfotainmentData((prev) => ({ ...prev, loading: false }));
      notification.error({
        message: error?.response?.data?.detail || "Failed to load Infotainment"
      });
    }
  };

  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      render: (text, record) => (
        <span
          style={{ color: "#1890ff", cursor: "pointer" }}
          onClick={() => setDrawerOpen({ visible: true, record, isEdit: true })}>
          {text}
        </span>
      )
    },
    { title: "Tag", dataIndex: "tag" },
    { title: "Frequency", dataIndex: "frequency" },
    { title: "Retention Period", dataIndex: "retention_period" },
    { title: "Xibo Folder", dataIndex: "xibo_folder_id" },
    {
      title: "Last Changed",
      dataIndex: "updated_at",
      render: (value) => (value ? dayjs(value).format("YYYY/MM/DD hh:mm A") : "N/A")
    },
    {
      title: "Last Run",
      dataIndex: "last_run",
      render: (value) => (value ? dayjs(value).format("YYYY/MM/DD hh:mm A") : "N/A")
    }
  ];

  const handleAdd = () => {
    setDrawerOpen({ visible: true, record: null, isEdit: false });
  };

  const handleDelete = async () => {
    if (!selectedRowKeys.length) return;
    try {
      await dispatch(deleteInfotainmentBulk(selectedRowKeys));
      notification.success({ message: "Infotainment deleted successfully" });
      loadInfotainment();
      setSelectedRowKeys([]);
    } catch (error) {
      notification.error({
        message: error?.response?.data?.detail || "Delete failed"
      });
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (keys) => setSelectedRowKeys(keys)
  };

  return (
    <TableWrapper>
      <div style={{ position: "relative" }}>
        <div
          style={{
            marginBottom: 16,
            display: "flex",
            justifyContent: "flex-end"
          }}>
          <Button
            style={{
              backgroundColor: "#39B54A",
              borderColor: "#39B54A",
              color: "#ffffff"
            }}
            onClick={handleDelete}
            disabled={!selectedRowKeys.length}>
            Delete
          </Button>
        </div>

        <Table
          columns={columns}
          dataSource={infotainmentData.data}
          loading={infotainmentData.loading}
          rowKey={(record) => record.id}
          pagination={false}
          rowSelection={rowSelection}
        />

        <Button
          type="primary"
          shape="circle"
          icon={<PlusOutlined style={{ fontSize: 20 }} />}
          size="large"
          onClick={handleAdd}
          style={{
            position: "fixed",
            right: 16,
            bottom: 16,
            zIndex: 1,
            width: 56,
            height: 56
          }}
        />
      </div>

      <DrawerComponent
        placement="right"
        drawerOpen={drawerOpen.visible}
        setDrawerOpen={(val) => setDrawerOpen((prev) => ({ ...prev, visible: val }))}>
        <InfotainmentDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} reload={loadInfotainment} />
      </DrawerComponent>
    </TableWrapper>
  );
};

export default Infotainment;
