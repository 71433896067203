import axios from "axios";

import { requestStart, requestCompleted, requestSuccess } from "../..";
import { notification } from "antd";

export function GetCampaigns(campaignsData, setCampaignsData) {
  return async (dispatch, state) => {
    const stateData = state();
    setCampaignsData({ ...campaignsData, loading: true });
    try {
      const companyId = !stateData.app.selectedCompany
        ? stateData.app.userProfile.company
        : stateData.app.selectedCompany;

      axios
        .get(process.env.REACT_APP_API_BASE_URL + `/campaign?offset=0&limit=100&company=${companyId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((res) => {
          if (res) {
            let keyData = res.data.map((data) => {
              return { ...data, key: data.id };
            });

            setCampaignsData({
              ...campaignsData,
              data: keyData,
              loading: false
            });
          }
        })
        .catch((error) => {
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
          dispatch(requestCompleted());
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function GetSingleCampaign(campaign, setCampaign, campaignName) {
  return async (dispatch) => {
    setCampaign({ ...campaign, loading: true });
    try {
      axios
        .get(process.env.REACT_APP_API_BASE_URL + `/campaign/${campaignName}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((res) => {
          if (res) {
            setCampaign({ ...campaign, data: res.data, loading: false });
          }
        })
        .catch((error) => {
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
          dispatch(requestCompleted());
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function UpdateSingleCampaign(data, navigate, setLoading) {
  return async (dispatch) => {
    try {
      setLoading(true);
      axios
        .put(process.env.REACT_APP_API_BASE_URL + `/campaign/manage`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((res) => {
          notification.success({
            message: "Campaign updated successfully"
          });
          setLoading(false);

          if (navigate) {
            navigate("/campaigns");
          }
        })
        .catch((error) => {
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
          dispatch(requestCompleted());
        });
    } catch (error) {
      dispatch(requestCompleted());
      throw error;
    }
  };
}

export function EditSingleCampaign(data, drawerOpen, setDrawerOpen, navigate) {
  return async (dispatch) => {
    try {
      axios
        .put(process.env.REACT_APP_API_BASE_URL + `/campaign/edit`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((res) => {
          notification.success({
            message: "Campaign updated successfully"
          });
          if (drawerOpen && setDrawerOpen) {
            setDrawerOpen({
              ...drawerOpen,
              CampaignDetailDrawer: false
            });
          }
          if (navigate) {
            navigate("/campaigns");
          }
        })
        .catch((error) => {
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
          dispatch(requestCompleted());
        });
    } catch (error) {
            console.log(error);
    }
  };
}

export function CreateCampaign(data, navigate, props = null) {
  return async (dispatch, state) => {
    try {
      return axios
        .post(process.env.REACT_APP_API_BASE_URL + `/campaign`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((res) => {
          notification.success({
            message: "Campaign created successfully"
          });

          if (props?.campaignsData) {
            dispatch(GetCampaigns(props.campaignsData, props.setCampaignsData));
          }

          if (navigate) navigate("/campaigns");
          if (props) props.setDrawerOpen(false);
        })
        .catch((error) => {
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
          dispatch(requestCompleted());
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function SortCampaign(column, order, setCampaignsData, campaignsData, selectedCompany) {
  return async (dispatch) => {
    setCampaignsData({ ...campaignsData, loading: true });

    try {
      axios
        .get(
          process.env.REACT_APP_API_BASE_URL +
            `/campaign/sort/${column}?order=${order}&company_id=${selectedCompany}&offset=0&limit=100`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }
        )
        .then((res) => {
          if (res) {
            let keyData = res.data.map((data) => {
              return { ...data, key: data.campaign_name };
            });

            setCampaignsData({
              ...campaignsData,
              data: keyData,
              loading: false
            });
          }
        })
        .catch((error) => {
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
          dispatch(requestCompleted());
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function CopyCampaign(id, editCampaign, setEditCampaign, setActionLoading) {
  return async (dispatch, state) => {
    try {
      setActionLoading(true);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + `/campaign/copy?campaign=${id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }
        )
        .then((res) => {
          if (res) {
            let stateData = state();

            if (res) {
              setActionLoading(false);
              setEditCampaign({
                ...editCampaign,
                data: res.data,
                loading: false
              });
            }

            let records = stateData.app.data;

            let filteredRecords = records.filter((record) => record.id !== id);

            let newData = [res.data, ...filteredRecords];

            dispatch(requestSuccess(newData));

            notification.success({
              message: "Campaign copied successfully"
            });
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          setActionLoading(false);
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function ArchiveCampaign(id, navigate, setActionLoading) {
  return async (dispatch, state) => {
    setActionLoading(true);
    try {
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + `/campaign/archive?campaign=${id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }
        )
        .then((res) => {
          if (res) {
            let stateData = state();

            let records = stateData.app.data;

            let filteredRecords = records.filter((record) => record.id !== id);

            let newData = [res.data, ...filteredRecords];

            dispatch(requestSuccess(newData));
            setActionLoading(false);

            navigate("/campaigns");
            notification.success({
              message: "Campaign Archived successfully"
            });
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          setActionLoading(false);
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}
export function bulkEditCampaign(data) {
  return async (dispatch) => {
    dispatch(requestStart());
    try {
      await axios
        .put(process.env.REACT_APP_API_BASE_URL + `/campaign/bulkEditCampaign`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((res) => {
          notification.success({
            message: "Bulk Edit Campaign complete."
          });
          dispatch(requestCompleted());
          // dispatch(GetCampaigns());
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}
