import axios from "axios";
import { requestCompleted, requestSuccess } from "../..";
import { notification } from "antd";
import { GetUserProfile } from "../Auth";

export function getCompanies(companies, setCompanies) {
  return async (dispatch) => {
    if (companies && setCompanies) setCompanies({ ...companies, loading: true });
    try {
      axios
        .get(process.env.REACT_APP_API_BASE_URL + `/company`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((res) => {
          if (companies && setCompanies) {
            setCompanies({ ...companies, data: res.data, loading: false });
          } else {
            if (res.data && res?.data?.length > 0) {
              let sortedData = res.data.sort((r1, r2) =>
                r1?.company_name.toLowerCase() > r2?.company_name.toLowerCase()
                  ? 1
                  : r1?.company_name.toLowerCase() < r2?.company_name.toLowerCase()
                    ? -1
                    : 0
              );
              dispatch(requestSuccess(sortedData));
            }
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export async function getCompanyCoupon(companyName) {
  try {
    const res = await axios.get(process.env.REACT_APP_API_BASE_URL + `/company`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
    const companyCouponOption = res.data.find((c) => c.id === companyName)?.coupon ?? false;

    return companyCouponOption;
  } catch (e) {
    console.log(e);
  }
}

export function EditCompany(data, setLoading, form, props, fetchCompanies, toggleFetchCompanies) {
  return async (dispatch, state) => {
    if (setLoading) setLoading(true);
    try {
      axios
        .put(process.env.REACT_APP_API_BASE_URL + "/company/edit", data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((res) => {
          if (res) {
            dispatch(requestSuccess());
            toggleFetchCompanies(!fetchCompanies);
            notification.success({
              message: "Company edited successfully"
            });

            if (state().app.selectedCompany === res.data.id) dispatch(GetUserProfile());

            if (setLoading) setLoading(false);
            if (props) props.setDrawerOpen({ ...props.drawerOpen, open: false });
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function addNewCompany(data, setLoading = () => {}, props, vistar) {
  return async (dispatch, state) => {
    setLoading(true);

    try {
      const res = await axios.post(process.env.REACT_APP_API_BASE_URL + "/company", data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });

      if (res) {
        dispatch(requestSuccess());
        props.toggleFetchCompanies(!props.fetchCompanies);
        notification.success({
          message: "Company created successfully"
        });

        setLoading(false);
        if (props) props.setDrawerOpen({ ...props.drawerOpen, open: false });
      }
    } catch (error) {
      dispatch(requestCompleted());
      notification["error"]({
        message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
      });
      setLoading(false);
    }
  };
}

export function getVistarEnv(setVistar) {
  return async (dispatch) => {
    try {
      axios
        .get(process.env.REACT_APP_API_BASE_URL + `/vistar-env`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((res) => {
          setVistar(res.data);
          requestCompleted();
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}
