import axios from "axios";
import { notification } from "antd";
import { requestStart, requestCompleted } from "../..";
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export function getInfotainmentList() {
  return async (dispatch) => {
    dispatch(requestStart());
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${BASE_URL}/infotainment`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(requestCompleted());
      return response.data;
    } catch (error) {
      dispatch(requestCompleted());
      notification.error({
        message: error?.response?.data?.detail || "Error fetching Infotainment list"
      });
      throw error;
    }
  };
}

export function createInfotainment(data) {
  return async (dispatch) => {
    dispatch(requestStart());
    try {
      const token = localStorage.getItem("token");
      await axios.post(`${BASE_URL}/infotainment`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(requestCompleted());
    } catch (error) {
      dispatch(requestCompleted());
      notification.error({
        message: error?.response?.data?.detail || "Error creating Infotainment"
      });
      throw error;
    }
  };
}

export function editInfotainment(data) {
  return async (dispatch) => {
    dispatch(requestStart());
    try {
      const token = localStorage.getItem("token");
      await axios.put(`${BASE_URL}/infotainment`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(requestCompleted());
    } catch (error) {
      dispatch(requestCompleted());
      notification.error({
        message: error?.response?.data?.detail || "Error updating Infotainment"
      });
      throw error;
    }
  };
}

export function deleteInfotainmentBulk(ids) {
  return async (dispatch) => {
    dispatch(requestStart());
    try {
      const token = localStorage.getItem("token");
      await axios.delete(`${BASE_URL}/infotainment`, {
        data: { ids },
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(requestCompleted());
    } catch (error) {
      dispatch(requestCompleted());
      notification.error({
        message: error?.response?.data?.detail || "Error deleting Infotainment"
      });
      throw error;
    }
  };
}
