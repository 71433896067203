import React, { useEffect } from "react";
import { Form, Input, InputNumber, Button, notification } from "antd";
import { useDispatch } from "react-redux";
import { createInfotainment, editInfotainment } from "Redux/App/Actions/Infotainment";
import { DrawerBodyPadding } from "Styles/global";

const InfotainmentDrawer = ({ drawerOpen, setDrawerOpen, reload }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const isEdit = drawerOpen.isEdit;
  const record = drawerOpen.record || {};

  useEffect(() => {
    if (isEdit && record) {
      form.setFieldsValue({
        category: record.category,
        url: record.url,
        tag: record.tag,
        xibo_folder_id: record.xibo_folder_id,
        retention_period: record.retention_period,
        frequency: record.frequency
      });
    } else {
      form.resetFields();
    }
  }, [isEdit, record]);

  const onFinish = async (values) => {
    try {
      if (isEdit) {
        await dispatch(editInfotainment({ id: record.id, ...values }));
        notification.success({ message: "Infotainment updated successfully" });
      } else {
        await dispatch(createInfotainment(values));
        notification.success({ message: "Infotainment created successfully" });
      }
      setDrawerOpen({ visible: false, record: null, isEdit: false });
      reload();
    } catch (error) {
      notification.error({
        message: error?.response?.data?.detail || "Operation failed"
      });
    }
  };

  const onClose = () => {
    setDrawerOpen({ visible: false, record: null, isEdit: false });
  };

  return (
    <div style={{ width: "100%", paddingTop: 16 }}>
      <DrawerBodyPadding>
        <h2 style={{ marginBottom: 24 }}>{isEdit ? "Edit Infotainment" : "Add Infotainment"}</h2>

        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item label="Category" name="category" rules={[{ required: true, message: "Category is required" }]}>
            <Input />
          </Form.Item>

          <Form.Item label="URL" name="url" rules={[{ required: true, message: "URL is required" }]}>
            <Input />
          </Form.Item>

          <Form.Item label="Tag" name="tag" rules={[{ required: true, message: "Tag is required" }]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="Xibo Folder ID"
            name="xibo_folder_id"
            rules={[{ required: true, message: "Xibo folder ID is required" }]}>
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            label="Frequency (days)"
            name="frequency"
            rules={[{ required: true, message: "Frequency is required" }]}>
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            label="Retention Period (days)"
            name="retention_period"
            rules={[{ required: true, message: "Retention period is required" }]}>
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <div style={{ display: "flex", gap: 8, marginTop: 24 }}>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" htmlType="submit">
              {isEdit ? "Update" : "Create"}
            </Button>
          </div>
        </Form>
      </DrawerBodyPadding>
    </div>
  );
};

export default InfotainmentDrawer;
