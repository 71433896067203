import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import { MenuWrapper } from "./styles";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCompanies } from "Redux/App/Actions/Companies";
import ActivityIcon from "Assets/Icons/ActivityIcon";
import SettingsIcon from "Assets/Icons/SettingsIcon";
import UserIcon from "Assets/Icons/UsersIcon";
import StatusIcon from "Assets/Icons/StatusIcon";
import { companyName, isEditor, isTemplate, setIsEditor, setIsTemplate, userProfileData } from "Redux/App";
import { StatisticsIcon } from "Assets/Icons/StatisticsIcon";
const NavMenu = () => {
  const [current, setCurrent] = useState(window.location.pathname.slice(1));
  const selectedCompany = useSelector(companyName);
  const userData = useSelector(userProfileData);
  const template = useSelector(isTemplate);
  const editor = useSelector(isEditor);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [comapnies, setCompanies] = useState({
    data: [],
    loading: false
  });
  useEffect(() => {
    if (userData && userData?.permission_level === "Fmtv") {
      dispatch(getCompanies(comapnies, setCompanies));
    }
  }, [userData]);
  const checkIsTemplateNavigation = (path) => {
    if (template) {
      if (path === "/template") {
        navigate(path);
      } else if (window.confirm("Navigating to another screen will discard the promotion. Do you wish to continue?")) {
        navigate(path);
        dispatch(setIsTemplate(false));
      } else {
        return;
      }
    } else if (editor) {
      if (path === "/create-promotion") {
        navigate(path);
      } else if (window.confirm("Navigating to another screen will discard the promotion. Do you wish to continue?")) {
        navigate(path);
        dispatch(setIsEditor(false));
      } else {
        return;
      }
    } else {
      navigate(path);
    }
  };

  const onClick = (e) => {
    setCurrent(e.key);
  };

  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label
    };
  }

  const items = [
    getItem(
      <p className={`${current === "promotions" || current === "create-menu" ? "white" : "gray"}`}>Promotions</p>,
      "promotions",
      <StatusIcon stroke={current === "promotions" || current === "create-menu" ? "#ffffff" : "#9B9B9B"} />,
      [
        getItem(
          <p className="gray" onClick={() => checkIsTemplateNavigation("/promotions")}>
            View
          </p>,
          "promotions"
        ),
        userData?.permission_level !== "Publisher" &&
          userData?.create_promo_flag &&
          getItem(
            <p className="gray" onClick={() => checkIsTemplateNavigation("/create-promotion")}>
              Create
            </p>,
            "create-menu"
          )
      ]
    ),
    getItem(
      <p className={`${current === "campaigns" ? "white" : "gray"}`}>Campaigns</p>,
      "campaigns",
      <ActivityIcon fill={current === "campaigns" ? "#ffffff" : "#9B9B9B"} />,
      [
        getItem(
          <p className="gray" onClick={() => checkIsTemplateNavigation("/campaigns")}>
            View
          </p>,
          "campaigns"
        )
      ]
    ),
    userData?.permission_level === "Fmtv" || userData?.permission_level === "Admin"
      ? getItem(
          <p className={`${current === "users" ? "white" : "gray"}`}>Users</p>,
          "users",
          <UserIcon fill={current === "users" ? "#ffffff" : "#9B9B9B"} />,
          [
            getItem(
              <p className="gray" onClick={() => checkIsTemplateNavigation("/users")}>
                Manage
              </p>,
              "users"
            )
          ]
        )
      : null,

    userData?.permission_level === "Fmtv"
      ? getItem(
          <p className={`${current === "domo" ? "white" : "gray"}`}>Domo</p>,
          "domo",
          <StatisticsIcon fill={current === "domo" ? "#ffffff" : "#9B9B9B"} />,
          [
            getItem(
              <p className="gray" onClick={() => checkIsTemplateNavigation("/domo")}>
                Manage
              </p>,
              "domo"
            )
          ]
        )
      : null,

    userData?.permission_level === "Fmtv"
      ? getItem(
          <p
            className={`${
              [
                "workflow",
                "template",
                "Companies",
                "Video Library",
                "Audio Library",
                "Image Library",
                "infotainment",
                "system",
                "logs"
              ].includes(current)
                ? "white"
                : "gray"
            }`}>
            Settings
          </p>,
          "Settings",
          <SettingsIcon
            fill={
              [
                "workflow",
                "template",
                "Companies",
                "Video Library",
                "Audio Library",
                "Image Library",
                "infotainment"
              ].includes(current)
                ? "#ffffff"
                : "#9B9B9B"
            }
          />,
          [
            getItem(
              <p className="gray" onClick={() => checkIsTemplateNavigation("/workflow")}>
                Workflow
              </p>,
              "workflow"
            ),
            getItem(
              <p className="gray" onClick={() => checkIsTemplateNavigation("/template")}>
                Template
              </p>,
              "template"
            ),
            getItem(
              <p className="gray" onClick={() => checkIsTemplateNavigation("/video-library")}>
                Video Library
              </p>,
              "Video Library"
            ),
            getItem(
              <p className="gray" onClick={() => checkIsTemplateNavigation("/audio-library")}>
                Audio Library
              </p>,
              "Audio Library"
            ),
            getItem(
              <p className="gray" onClick={() => checkIsTemplateNavigation("/image-library")}>
                Image Library
              </p>,
              "Image Library"
            ),
            getItem(
              <p className="gray" onClick={() => checkIsTemplateNavigation("/companies")}>
                Companies
              </p>,
              "Companies"
            ),
            getItem(
              <p className="gray" onClick={() => checkIsTemplateNavigation("/infotainment")}>
                Infotainment
              </p>,
              "infotainment"
            ),
            getItem(
              <p className={`${current === "system" || current === "logs" ? "white" : "gray"}`}>System Settings</p>,
              "systemSetting",
              null,
              [
                getItem(
                  <p className="gray" onClick={() => checkIsTemplateNavigation("/system-settings")}>
                    System
                  </p>,
                  "system"
                ),
                getItem(
                  <p className="gray" onClick={() => checkIsTemplateNavigation("/logs")}>
                    Logs
                  </p>,
                  "logs"
                )
              ]
            )
          ]
        )
      : null
  ];

  return (
    <MenuWrapper>
      <Menu
        onClick={onClick}
        selectedKeys={[current]}
        defaultOpenKeys={[current]}
        mode="inline"
        theme="light"
        items={items}
      />
    </MenuWrapper>
  );
};

export default NavMenu;
