import axios from "axios";
import { requestStart, requestCompleted, requestSuccess, setTheModalOpened } from "../..";
import { setPromotionsData } from "Redux/App/dataSlice";
import { notification } from "antd";

export function GetPromos({ sortColumn, sortOrder, searchTerm, page, page_size } = {}) {
  return async (dispatch, getState) => {
    dispatch(requestStart());
    const stateData = getState();
    const company = stateData?.app?.selectedCompany;

    const params = new URLSearchParams();
    params.append("company", company);
    if (sortColumn) {
      params.append("column", sortColumn);
      params.append("order", sortOrder || "desc");
    } else {
      params.append("order", "desc");
    }
    if (searchTerm) {
      params.append("search_term", searchTerm);
    }
    params.append("page", page !== undefined ? page : 0);
    params.append("page_size", page_size !== undefined ? page_size : 100);
    params.append("column", "date_updated");

    try {
      const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/promo?${params.toString()}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      if (res.data) {
        const promotions = res.data.promotion ? res.data.promotion : res.data;
        dispatch(setPromotionsData(promotions));
      }
      dispatch(requestCompleted());
    } catch (error) {
      notification.error({
        message: error?.response?.data?.detail || "Something went wrong"
      });
      dispatch(requestCompleted());
    }
  };
}
export function UploadPromotion(data, handleCancel, setUploadPromotionLoading, couponOptions) {
  return async (dispatch, State) => {
    setUploadPromotionLoading(true);
    try {
      data = { ...data, coupon: couponOptions.coupon };
      if (couponOptions.coupon && couponOptions.coupon_details.title) {
        data.coupon_details = couponOptions.coupon_details;
      }

      const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/promo`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });

      notification.success({
        message: "Promotion created successfully"
      });

      const stateData = State();
      const currentItems = stateData.promotions?.data?.items || [];
      const updatedItems = [res.data, ...currentItems];
      const updatedPromotionsData = {
        ...stateData.promotions.data,
        items: updatedItems
      };
      dispatch(requestSuccess(updatedPromotionsData));

      handleCancel();
      setUploadPromotionLoading(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      setUploadPromotionLoading(false);
      dispatch(requestCompleted());
      notification.error({
        message: error?.response?.data?.detail || "Something went wrong"
      });
    }
  };
}

export function EditPromotion(data, handleCancel, setEditPromotionLoading, screen, refreshPromotions) {
  return async (dispatch, state) => {
    setEditPromotionLoading(true);
    try {
      axios
        .put(process.env.REACT_APP_API_BASE_URL + "/promo/edit", data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((res) => {
          if (res) {
            if (screen === "promotion") {
              let stateData = state();
              let records = stateData.promotions.data;
              let updatedRecords = records.map((rec) => (rec.id === data.promo_id ? res.data.promotion : rec));
              dispatch(setPromotionsData(updatedRecords));
              dispatch(requestSuccess(updatedRecords));
            }
            notification.success({
              message: "The promotion edited successfully"
            });
            handleCancel();
            setEditPromotionLoading(false);
          }
        })
        .catch((error) => {
          setEditPromotionLoading(false);
          dispatch(requestCompleted());
          refreshPromotions();
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function CopyPromotion(data, setLoading, handleCancel, setSelectedRowKeys) {
  return async (dispatch, state) => {
    setLoading(true);
    try {
      axios
        .post(process.env.REACT_APP_API_BASE_URL + "/promo/copy", data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((res) => {
          if (res) {
            if (
              !res?.data?.msg?.includes("The following promotion(s) were not copied since their copy exists already.")
            ) {
              let stateData = state();
              let records = stateData.promotions.data;

              let newData = [...res.data.promotion, ...records];
              dispatch(setPromotionsData(newData));
              dispatch(requestSuccess(newData));
            }

            notification.success({
              message: res?.data?.msg
            });
            setSelectedRowKeys([]);
            setLoading(false);
            handleCancel();
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
          setLoading(false);
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function PublishPromotion(data, handleCancel, setSelectedRowKeys, refreshPromos) {
  return async (dispatch, state) => {
    dispatch(setTheModalOpened());
    try {
      notification["success"]({
        message: "Successfully queued the promotion up to be published"
      });
      handleCancel();

      let stateData = state();
      let records = stateData.promotions.data;
      let result = records.filter((item) => data.includes(item.id));
      let checkDates = result.filter((item) => !item.effective_date || !item.end_date);
      if (checkDates.length > 0) {
        notification.error({
          message: "Unable to publish promotion because Effective date or end date is not specified."
        });
        return;
      }
      axios
        .put(process.env.REACT_APP_API_BASE_URL + "/promo/publish", data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((res) => {
          if (res) {
            if (
              !res?.data?.msg?.includes(
                " The following promotion(s) failed to publish since they are currently in a Paused, Pending,Active,Upcoming or Expired state: "
              )
            ) {
              let updatedRecords = records.map((rec) => {
                if (rec.id === res.data.promotion[0].id) {
                  return {
                    ...res.data.promotion[0],
                    thumbnail_url: rec.thumbnail_url
                  };
                }
                return rec;
              });

              dispatch(setPromotionsData(updatedRecords));
              dispatch(requestSuccess(updatedRecords));
            }
            dispatch(requestCompleted());
            setSelectedRowKeys([]);
            refreshPromos();
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          refreshPromos();
          setSelectedRowKeys([]);
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function DeactivatePromotion(data, handleCancel, setSelectedRowKeys) {
  return async (dispatch, state) => {
    try {
      notification["success"]({
        message: "Successfully queued the promotion up to be deactivated"
      });
      handleCancel();

      axios
        .put(process.env.REACT_APP_API_BASE_URL + "/promo/deactivate", data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((res) => {
          if (res) {
            let stateData = state();
            let updatedStateData = stateData.promotions.data.map((item) => {
              if (data.includes(item.id)) {
                item = { ...item, status: "Pending" };
              }
              return item;
            });
            dispatch(setPromotionsData(updatedStateData));
            dispatch(requestSuccess(updatedStateData));
            setSelectedRowKeys([]);
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          setSelectedRowKeys([]);
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function ArchievePromotion(data, setLoading, handleCancel, setSelectedRowKeys) {
  return async (dispatch, state) => {
    setLoading(true);
    try {
      axios
        .put(process.env.REACT_APP_API_BASE_URL + "/promo/archive", data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((res) => {
          let stateData = state();
          if (res) {
            if (res?.data?.msg === "Promotion(s) Archived") {
              let updatedStateData = stateData.promotions.data.map((item) => {
                if (data.includes(item.id)) {
                  item = { ...item, status: "Archived" };
                }
                return item;
              });

              dispatch(setPromotionsData(updatedStateData));
            }
            notification.success({
              message: res?.data?.msg
            });
            setSelectedRowKeys([]);
            setLoading(false);
            handleCancel();
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
          setLoading(false);
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function SortPromos(column, order, selectedCompany) {
  return async (dispatch) => {
    dispatch(requestStart());
    try {
      axios
        .get(
          process.env.REACT_APP_API_BASE_URL +
            `/promo/sort/${column}?order=${order}&company_id=${selectedCompany}&offset=0&limit=100`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }
        )
        .then((res) => {
          if (res) {
            dispatch(setPromotionsData(res.data));
            dispatch(requestSuccess(res.data));
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
          dispatch(requestCompleted());
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function GetSinglePromos(promoName, promotionData, setPromotionData, setSelectedRowKeys) {
  return async (dispatch) => {
    setPromotionData({ ...promotionData, loading: true });
    try {
      axios
        .get(process.env.REACT_APP_API_BASE_URL + `/promo/${promoName}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((res) => {
          if (res) {
            if (!res.data.campaigns[0] === "None") {
              setSelectedRowKeys(Object.keys(res.data.campaigns));
            }

            setPromotionData({
              ...promotionData,
              loading: false,
              data: res.data,
              keys: res.data.campaigns[0] === "None" ? [] : Object.keys(res.data.campaigns)
            });
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
          dispatch(requestCompleted());
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function UpdateCoupan(screen, data, setLoading, props, setPromotionData) {
  return async (dispatch, state) => {
    setLoading(true);
    try {
      axios
        .put(process.env.REACT_APP_API_BASE_URL + `/promo/editcoupon`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((res) => {
          if (res) {
            if (screen === "Edit Coupon Screen") {
              props.setDrawerOpen({
                ...props.drawerOpen,
                EditPromotionDrawer: true,
                EditCouponDrawer: false,
                editPromotionData: res.data
              });
            }
            let {
              app: { data: appData }
            } = state();

            const appDataArr = Array.isArray(appData) ? appData : appData.filterData;

            let updatedPromotion = {};
            let updated_record = appDataArr.map((item) => {
              if (item.id === data.promo_id) {
                item = {
                  ...item,
                  coupon: data.coupon,
                  coupon_details: res?.data?.coupon_details
                };
                updatedPromotion = item;
              }
              return item;
            });
            if (setPromotionData) setPromotionData({ data: updatedPromotion });
            dispatch(requestSuccess(updated_record));
            setLoading(false);
            notification.success({
              message: "Coupon updated successfully"
            });
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function GetPromoVideo(promo_id, promoVideo, setPromoVideo, downloadFile = () => {}, fileName) {
  return async (dispatch) => {
    setPromoVideo({ ...promoVideo, loading: true, modalVisible: true });
    try {
      axios
        .get(process.env.REACT_APP_API_BASE_URL + `/promo/${promo_id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((res) => {
          if (res) {
            downloadFile(res.data, {
              ...promoVideo,
              loading: false,
              data: res?.data,
              mediaType: res?.data?.media_type,
              fileName
            });
          }
        })
        .catch((error) => {
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
          dispatch(requestCompleted());
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function UpdateThumbnail(promoID, time) {
  return async (dispatch) => {
    dispatch(requestStart());
    try {
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + `/promo/thumbnail/update?promo_id=${promoID}&timestamp=${time}`,
          null,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }
        )
        .then((res) => {
          notification.success({
            message: "The thumbnail updated successfully"
          });

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification["error"]({
            message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
          });
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function copyPromotionToTemplate(promoId) {
  return async (dispatch) => {
    dispatch(requestStart());
    try {
      const data = [promoId];
      const res = await axios.post(process.env.REACT_APP_API_BASE_URL + "/promo/copy", data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      dispatch(requestCompleted());
      return res.data;
    } catch (error) {
      dispatch(requestCompleted());
      notification["error"]({
        message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
      });
      throw error;
    }
  };
}
export function bulkEditPromotions({ promotions, date = null, type = null, campaignId = null }) {
  return async (dispatch) => {
    dispatch(requestStart());

    const isoDate = date ? date.toISOString() : null;

    const dataToSend = promotions.map((promoId) => ({
      id: promoId,
      start_date: type === "start" ? isoDate : null,
      end_date: type === "end" ? isoDate : null,
      campaign_id: campaignId ? campaignId[0] : null
    }));

    try {
      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/promo/bulk`, dataToSend, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });

      notification.success({
        message: "Bulk Edit complete."
      });
      dispatch(GetPromos());
    } catch (error) {
      notification.error({
        message: error?.response?.data?.detail || "Something went wrong"
      });
    } finally {
      dispatch(requestCompleted());
    }
  };
}
